import { SELECT, SWITCH, TEXT } from "../../components/Common/Const";

export const MailSettingsConst = [
  {
    name: "email",
    label: "Email",
    type: TEXT,
    isRequired: true,
    requiredErrorMessage: "Please enter Email.",
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "userName",
    label: "User Name",
    type: TEXT,
    isRequired: true,
    requiredErrorMessage: "Please enter UserName.",
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "password",
    label: "Password",
    type: TEXT,
    isRequired: true,
    requiredErrorMessage: "Please enter Password.",
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "mailType",
    label: "Mail Type",
    options: [
      { label: "Select Mail Type", value: "0" },
      { label: "Gmail", value: 1 },
      { label: "Smtp", value: 2 },
    ],
    isRequired: true,
    type: SELECT,
    defaultValue: "0",
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "smtpAddress",
    label: "Smtp Address",
    type: TEXT,
    isRequired: true,
    requiredErrorMessage: "Please enter Smtp Address.",
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "portNumber",
    label: "Port",
    type: TEXT,
    isRequired: true,
    requiredErrorMessage: "Please enter Port.",
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "isEnableSSL",
    label: "Enable SSL",
    parentclassName: "",
    defaultValue: false,
    type: SWITCH,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "isDefault",
    label: "Is Default",
    parentclassName: "",
    defaultValue: false,
    type: SWITCH,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "isActive",
    label: "Is Active",
    parentclassName: "",
    defaultValue: true,
    type: SWITCH,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
];
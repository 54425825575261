import { SELECT, SWITCH, TEXT } from "../../components/Common/Const";

export const ClientConst = [
  {
    name: "fullName",
    label: "FullName",
    type: TEXT,
    isRequired: true,
    requiredErrorMessage: "Please enter FullName.",
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "userName",
    label: "UserName",
    type: TEXT,
    isRequired: true,
    requiredErrorMessage: "Please enter UserName.",
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "isAllowMultiLogin",
    label: "AllowMultiLogin",
    parentclassName: "",
    defaultValue: false,
    type: SWITCH,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "isDelete",
    label: "Delete",
    parentclassName: "",
    defaultValue: false,
    type: SWITCH,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "isEmailVerified",
    label: "EmailVerified",
    parentclassName: "",
    defaultValue: false,
    type: SWITCH,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "emailId",
    label: "Email Id",
    type: TEXT,
    isRequired: true,
    requiredErrorMessage: "Please enter Email Id.",
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "isMobileVerified",
    label: "MobileVerified",
    parentclassName: "",
    defaultValue: false,
    type: SWITCH,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "mobileNo",
    label: "Mobile No",
    type: TEXT,
    isRequired: true,
    requiredErrorMessage: "Please enter Mobile No.",
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  }, 
  {
    name: "registrationProcessStatus",
    label: "Process Status",
    options: [
      { label: "Select Process Status", value: "0" },
      { label: "Added User Details", value: 1 },
      { label: "Mobile/Email Verified", value: 2 },
      { label: "Password set", value: 3 },
    ],
    isRequired: true,
    type: SELECT,
    defaultValue: "0",
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "provider",
    label: "Provider",
    options: [
      { label: "Select Provider Type", value: "0" },
      { label: "Manual", value: 1 },
      { label: "Google", value: 2 },
      { label: "Facebook", value: 3 },
    ],
    isRequired: true,
    type: SELECT,
    defaultValue: "0",
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  }, 
  {
    name: "isUserActive",
    label: "User Status",
    options: [
      // { label: "Select User Status", value: "0" },
      { label: "Inactive", value: "0" },
      { label: "Active", value: 1 },
    ],
    type: SELECT,
    defaultValue: "0",
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "isActive",
    label: "Is Active",
    parentclassName: "",
    defaultValue: true,
    type: SWITCH,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
];

import { DATE_TIME_PICKER, SELECT, SWITCH, TEXT } from "../../components/Common/Const";

export const EventFields = [
    {
        name: "eventTypeId",
        label: "Event Type",
        parentclassName: "",
        isRequired: true,
        type: SELECT,
        options: [{ label: "Select Event Type", value: "0" }],
        defaultValue: "0",
        labelColspan: { xs: 12, md: 2, lg: 2 },
        fieldColspan: { xs: 12, md: 4, lg: 4 }
    },
    {
        name: "competitionId",
        label: "Competition",
        parentclassName: "",
        isRequired: true,
        type: SELECT,
        options: [{ label: "Select a Competition", value: "0" }],
        defaultValue: "0",
        labelColspan: { xs: 12, md: 2, lg: 2 },
        fieldColspan: { xs: 12, md: 4, lg: 4 }
    },
    {
        name: "eventName",
        label: "Event Name",
        parentclassName: "",
        isRequired: true,
        regex: /^.{0,500}$/,
        regexErrorMessage: "Max allowed Characters 500",
        labelColspan: { xs: 12, md: 2, lg: 2 },
        fieldColspan: { xs: 12, md: 4, lg: 4 },
        type: TEXT,
    },
    {
        name: "refId",
        label: "Reference Id",
        parentclassName: "",
        isRequired: true,
        regex: /^.{0,100}$/,
        regexErrorMessage: "Max allowed Characters 100",
        labelColspan: { xs: 12, md: 2, lg: 2 },
        fieldColspan: { xs: 12, md: 4, lg: 4 },
        type: TEXT,
    },
    {
        name: "eventDate",
        label: "Event Date",
        isRequired: true,
        labelColspan: { xs: 12, md: 2, lg: 2 },
        fieldColspan: { xs: 12, md: 4, lg: 4 },
        type: DATE_TIME_PICKER,
    },
    {
        name: "countryCode",
        label: "Country Code",
        parentclassName: "",
        labelColspan: { xs: 12, md: 2, lg: 2 },
        fieldColspan: { xs: 12, md: 4, lg: 4 },
        type: TEXT,
    },
    {
        name: "timeZone",
        label: "TimeZone",
        parentclassName: "",
        regex: /^.{0,20}$/,
        regexErrorMessage: "Max allowed Characters 20",
        labelColspan: { xs: 12, md: 2, lg: 2 },
        fieldColspan: { xs: 12, md: 4, lg: 4 },
        type: TEXT,
    },
    {
        name: "venue",
        label: "Venue",
        parentclassName: "",
        regex: /^.{0,100}$/,
        regexErrorMessage: "Max allowed Characters 100",
        labelColspan: { xs: 12, md: 2, lg: 2 },
        fieldColspan: { xs: 12, md: 4, lg: 4 },
        type: TEXT,
    },
    {
        name: "isActive",
        label: "Is Active",
        defaultValue: true,
        parentclassName: "",
        labelColspan: { xs: 12, md: 2, lg: 2 },
        fieldColspan: { xs: 12, md: 4, lg: 4 },
        type: SWITCH,
    },
]
import { IMAGE, SELECT, SWITCH, TEXT } from "../../components/Common/Const";

export const NotificationConst = [
  {
    name: "sendType",
    label: "Send Type",
    options: [
      { label: "Select Send Type", value: "0" },
      { label: "all", value: 1 },
      { label: "onlyLoggedInUser", value: 2 },
      { label: "pushNotification", value: 3 },
    ],
    isRequired: true,
    type: SELECT,
    defaultValue: false,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "commentaryId",
    label: "Commentary Type",
    options: [{ label: "Select Commentary Type", value: "0" }],
    isRequired: true,
    type: SELECT,
    defaultValue: "0",
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "title",
    label: "Title",
    type: TEXT,
    isRequired: true,
    requiredErrorMessage: "Please enter Title.",
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "description",
    label: "Message",
    type: TEXT,
    isRequired: true,
    requiredErrorMessage: "Please enter Message.",
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    type: IMAGE,
    name: "image",
    label: "Image",
    parentclassName: "",
  },
  {
    type: IMAGE,
    name: "icon",
    label: "Icon",
    parentclassName: "",
  },
  {
    name: "url",
    label: "Url",
    type: TEXT,
    isRequired: true,
    requiredErrorMessage: "Please enter Url.",
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "isSend",
    label: "Send Now",
    parentclassName: "",
    defaultValue: false,
    type: SWITCH,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  // {
  //   name: "isActive",
  //   label: "Is Active",
  //   parentclassName: "",
  //   defaultValue: true,
  //   type: SWITCH,
  //   labelColspan: { xs: 12, md: 2, lg: 2 },
  //   fieldColspan: { xs: 12, md: 4, lg: 4 },
  // },
];

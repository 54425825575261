import { IMAGE, MULTI_SELECT, SELECT, TEXT, COLOR_PICKER } from "../../components/Common/Const";

export const TeamFields = [
    {
        type: SELECT,
        name: "eventTypeId",
        label: "Event Type",
        parentclassName: "",
        isRequired: true,
        requiredErrorMessage: "Please enter event type.",
        options: [{ label: "Select Event Type", value: "0" }],
        defaultValue: "0",
        labelColspan: { xs: 12, md: 2, lg: 2 },
        fieldColspan: { xs: 12, md: 4, lg: 4 }
    },
    {
        type: TEXT,
        name: "teamName",
        label: "Team Name",
        parentclassName: "",
        isRequired: true,
        regex: /^.{0,100}$/,
        regexErrorMessage: "Max allowed Characters 100",
        labelColspan: { xs: 12, md: 2, lg: 2 },
        fieldColspan: { xs: 12, md: 4, lg: 4 },
        requiredErrorMessage: "Please enter team name.",
    },
    {
        type: TEXT,
        name: "teamShortName",
        label: "Short Name",
        parentclassName: "",
        isRequired: true,
        regex: /^.{0,100}$/,
        regexErrorMessage: "Max allowed Characters 100",
        labelColspan: { xs: 12, md: 2, lg: 2 },
        fieldColspan: { xs: 12, md: 4, lg: 4 },
        requiredErrorMessage: "Please enter short name.",
    },
    {
        type: TEXT,
        name: "country",
        label: "Country",
        parentclassName: "",
        regex: /^[a-zA-Z0-9 ]{0,100}$/,
        regexErrorMessage: "Max allowed Characters 100, No Spacial Character",
        labelColspan: { xs: 12, md: 2, lg: 2 },
        fieldColspan: { xs: 12, md: 4, lg: 4 },
    },
    {
        type: IMAGE,
        name: "jersey",
        label: "Jersey Image",
        parentclassName: "",

    },
    {
        type: IMAGE,
        name: "image",
        label: "Team Image",
        parentclassName: "",
    },
    {
        type: MULTI_SELECT,
        name: "playerId",
        label: "Select Players",
        options: [],
        showSelectAll: true,
        labelColspan: { xs: 12, md: 2, lg: 2 },
        fieldColspan: { xs: 12, md: 4, lg: 4 },
    },
    {
        type: MULTI_SELECT,
        name: "competitionId",
        label: "Select Competition",
        options: [],
        showSelectAll: true,
        labelColspan: { xs: 12, md: 2, lg: 2 },
        fieldColspan: { xs: 12, md: 4, lg: 4 },
    },
    {
        type: COLOR_PICKER,
        name: "teamColor",
        label: "Team Color",
        parentclassName: "",
    },
    {
        type: COLOR_PICKER,
        name: "backgroundColor",
        label: "Background Color",
        parentclassName: "",
    },
]
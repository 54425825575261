import { SWITCH, TEXT } from "../../components/Common/Const";

export const ClientSocketFields = [
    {
        type: TEXT,
        name: "serverName",
        label: "Server Name",
        parentclassName: "",
        isRequired: true,
        regex: /^.{0,100}$/,
        regexErrorMessage: "Max allowed Characters 100",
        labelColspan: { xs: 12, md: 2, lg: 2 },
        fieldColspan: { xs: 12, md: 4, lg: 4 },
        requiredErrorMessage: "Please enter server name.",
    },
    {
        type: TEXT,
        name: "url",
        label: "URL",
        parentclassName: "",
        isRequired: true,
        labelColspan: { xs: 12, md: 2, lg: 2 },
        fieldColspan: { xs: 12, md: 4, lg: 4 },
        requiredErrorMessage: "Please enter url.",
    },
    {
        type: TEXT,
        name: "reconnectDelay",
        label: "Reconnect Delay",
        parentclassName: "",
        isRequired: true,
        labelColspan: { xs: 12, md: 2, lg: 2 },
        fieldColspan: { xs: 12, md: 4, lg: 4 },
        requiredErrorMessage: "Please enter reconnect delay.",
    },
    {
        type: TEXT,
        name: "reconnectAttempts",
        label: "Reconnect Attempts",
        parentclassName: "",
        isRequired: true,
        labelColspan: { xs: 12, md: 2, lg: 2 },
        fieldColspan: { xs: 12, md: 4, lg: 4 },
        requiredErrorMessage: "Please enter reconnect attempts.",
    },
    {
        type: TEXT,
        name: "reconnectMaxDelay",
        label: "Reconnect Max Delay",
        parentclassName: "",
        isRequired: true,
        labelColspan: { xs: 12, md: 2, lg: 2 },
        fieldColspan: { xs: 12, md: 4, lg: 4 },
        requiredErrorMessage: "Please enter reconnect max delay.",
    },
    {
        name: "isActive",
        label: "Is Active",
        defaultValue: true,
        parentclassName: "",
        type: SWITCH,
    },
]
import { SELECT, SWITCH, TEXT } from "../../components/Common/Const";

export const APIEndpointConst = [
  {
    name: "serviceType",
    label: "Service Type",
    options: [
      { label: "Select Service Type", value: "0" },
      { label: "clientAPI", value: 1 },
      { label: "dataProviderAPI", value: 2 },
    ],
    isRequired: true,
    type: SELECT,
    defaultValue: false,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "endPoint",
    label: "EndPoint",
    type: TEXT,
    isRequired: true,
    requiredErrorMessage: "Please enter EndPoint.",
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "moduleType",
    label: "Module Type",
    options: [
      { label: "Select Module Type", value: "0" },
      { label: "commentaryUpdate", value: 1 },
      { label: "vendorUpdate", value: 2 },
      { label: "vendorIpUpdate", value: 3 },
    ],
    isRequired: true,
    type: SELECT,
    defaultValue: false,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "timeOut",
    label: "TimeOut",
    type: TEXT,
    isRequired: true,
    requiredErrorMessage: "Please enter TimeOut.",
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
  {
    name: "isActive",
    label: "Is Active",
    parentclassName: "",
    defaultValue: true,
    type: SWITCH,
    labelColspan: { xs: 12, md: 2, lg: 2 },
    fieldColspan: { xs: 12, md: 4, lg: 4 },
  },
];

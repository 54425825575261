import { SELECT, SWITCH, TEXT } from "../../components/Common/Const";

export const UserFields = [
    {
        name: "parentId",
        label: "Parent Name",
        parentclassName: "",
        type: SELECT,
        options: [{ label: "Select Parent", value: "0" }],
        defaultValue: "0",
        isRequired: true,
        requiredErrorMessage: "Please select a parent name.",
    },
    {
        name: "userType",
        label: "User Type",
        parentclassName: "",
        type: SELECT,
        defaultOption: { label: "Select a Role", value: "Select a Display Type" },
        options: [
            { label: "Admin", value: 1 },
            { label: "Agent", value: 2 },
            { label: "Client", value: 3 },],
        isRequired: true,
        requiredErrorMessage: "Please select a user type.",
    },
    {
        name: "roleId",
        label: "Role",
        parentclassName: "",
        type: SELECT,
        options: [{ label: "Select a Role", value: "0" }],
        defaultValue: "0",
        isRequired: true,
        requiredErrorMessage: "Please select a role.",
    },
    {
        name: "name",
        label: "Full Name",
        parentclassName: "",
        type: TEXT,
        isRequired: true,
        regex: /^[a-zA-Z0-9 ]{1,100}$/,
        regexErrorMessage: "Max allowed Characters 100, No Spacial Character",
        requiredErrorMessage: "Please enter full name.",
    },
    {
        name: "userName",
        label: "User Name",
        parentclassName: "",
        type: TEXT,
        isRequired: true,
        requiredErrorMessage: "Please enter user name.",
        regex: /^[a-zA-Z0-9 ]{1,50}$/,
        regexErrorMessage: "Max allowed Characters 50, No Spacial Character",
    },
    {
        name: "password",
        label: "Password",
        parentclassName: "",
        type: "password",
        isRequired: true,
        labelColspan: { xs: 12, md: 2, lg: 2 },
        fieldColspan: { xs: 12, md: 4, lg: 4 },
        requiredErrorMessage: "Please enter password.",
    },
    {
        name: "mobile",
        label: "Mobile",
        parentclassName: "",
        type: TEXT,
        regex: /^(?!\d{13,}$)(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$|^$/,
        regexErrorMessage: "Invalid mobile number",
    },
    {
        name: "allowMultipleLogin",
        label: "Allow Multiple Login",
        parentclassName: "",
        type: SWITCH
    },
    {
        name: "isActive",
        label: "Is Active",
        defaultValue: true,
        parentclassName: "",
        type: SWITCH,
    },
]